import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import dayjs from 'dayjs';
import {
  NgbDropdownModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faHouse,
  faMagnifyingGlass,
  faPhone,
  faCaretDown,
  faCaretUp,
  faXmark,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { NewstickerComponent } from '../parts/newsticker/newsticker.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-header-search',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './header-search.component.html',
  styleUrl: './header-search.component.scss',
  imports: [
    RouterModule,
    NgbDropdownModule,
    NgbCollapseModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    NewstickerComponent
  ],
})
export class HeaderSearchComponent {
  subscription: Subscription = new Subscription();
  searchValue: string = '';

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faPowerOff: IconProp = faPowerOff;
  @Input() activeMenu: string = 'home';

  isCollapsed: boolean = true;

  balance: number = 0;
  username: string = '';
  isLoggedin: boolean = false;
  loadBalance: boolean = false;

  newstickerData: any = this.data.newstickerJSONData;
  categoryProviderData: any = this.data.categoryproviderJSONData;

  currentScreenSize: string = '';

  time: string =
    dayjs().format('DD-MM-YYYY HH:mm') +
    ' (GMT' +
    dayjs().format('Z)').replace(':00', '');

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    public data: DataService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      [Breakpoints.Small, 'small'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  searchItem() {
    if (this.searchValue) {
      this.router.navigate(['/search'], {
        queryParams: { value: this.searchValue },
      });
    } else {
      this.router.navigate(['/search']);
    }
  }
  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.logout(this.auth.sessionValue).subscribe({
        next: (response) => {
          if (response.code === 200 && response.data.status) {
            this.auth.setLogout();
          } else {
            console.error('Logout failed:', response.message);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
        complete: () => {
          window.location.reload();
          // this.router.navigate(['/']);
          console.log(this.auth.sessionValue);
        },
      });
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          // var parts = v.toString().split('.');
          // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          // this.balance = parts.join('.');
          this.balance = valTrunc;
          this.loadBalance = true;
        },
      })
    );
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
        },
      })
    );
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.username = this.auth.userProfileData['login_id'];
      this.subscriptionData();
    }
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
