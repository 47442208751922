import { Component, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretLeft, faCaretRight, faMagnifyingGlass, faCaretDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CommonModule,DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {PageEvent, MatPaginatorModule} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { Location } from '@angular/common';
import { FilterMobileComponent } from '../filter-mobile/filter-mobile.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { RouterExtService } from '@componentpath/services/scripts.service';
@Component({
  selector: 'app-statement-mobile',
  standalone: true,
  imports: [MatPaginatorModule,HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, CommonModule],
  templateUrl: './statement-mobile.component.html',
  styleUrl: './statement-mobile.component.scss'
})
export class StatementMobileComponent {
  subscription: Subscription = new Subscription();
  prevURL: string = '';
  part: string = 'monthly';

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private dataService: DataService,
    private auth: AuthService,
    private request: RequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    private routerExtService: RouterExtService
  ) {
    const today = dayjs();
    this.currentDate = today.format('YYYY-MM-DD');
  }
  
  providerListsAll: Array<any> = [];
  filterProviderLists: Array<any> = [];
  faCaretLeft: IconProp = faCaretLeft;
  faCaretRight: IconProp = faCaretRight;
  faMagnifyingGlass : IconProp = faMagnifyingGlass;
  faCaretDown: IconProp = faCaretDown;
  faArrowLeft: IconProp = faArrowLeft;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  statement: any = {
    monthly: {
      id: null,
      result: null
    },
    provider: {
      id: null,
      result: null
    },
    detail: {
      id: null,
      result: null
    }
  }

  month: any = {
    code: 0,
    value: dayjs().add(0, 'month').format('MMMM YYYY')
  }


  limitDate: any;
  currentDate: string;
  currentMonth: number = 0;
  months = MONTHS;

  subtitle: any = '';

  page: any = {
    monthly: 1,
    provider: 1,
    detail: 1
  };

  limit: number = 50;
  pagination: any = {
    monthly: [],
    provider: [],
    detail: []
  }

  //table variable
  length = 0;
  pageSize = 50;
  pageIndex = 0;
  pageSizeOptions = [50];
  showPageSizeOptions = true;
  maxPage = 0;

  pageEvent!: PageEvent;

  showDropdown : boolean = false;

  isLoadingDetail: boolean = false;

  openFilter() {
    let currentFilter = {
      provider: this.providerId
    }
    let dataFilter = {
      type: 'statement',
      data: {
        currentFilter: currentFilter,
        providerList: this.providerListsAll,
      }
    }
    this.bottomSheet.open(FilterMobileComponent, {
      data: dataFilter
    }).afterDismissed().subscribe((v) => {
      // console.log(v)
      this.onSelectFilter(v);
    })
  }

  onSelectFilter(e: any): void {
    this.statement.detail.result == null
    this.providerId = e.provider;
    this.providerName = e.name;
    this.statement.detail = {
      id: null,
      result: null
    };
    this.getDataDetail(1, this.currentDate);
  }

  onScroll(event: any) {
    if(this.pageIndex <= this.maxPage){
      this.checkScrollBottom();
    }
  }

  checkScrollBottom() {
    const element = this.doc.getElementById('dateTable');
    if (!element) return;
    const isScrolledBottom = element.scrollTop + element.offsetHeight >= element.scrollHeight-5;
    if (isScrolledBottom && !this.isLoadingDetail) {
      this.isLoadingDetail = true;
      this.getDataDetail(this.pageIndex+ 1, this.currentDate);
    }
}

goBack(): void {
  if (isPlatformBrowser(this.platformId)) {
    this.prevURL = this.routerExtService.getPreviousUrl();
    // const currentUrl = window.location.href;
    // const referrerUrl = document.referrer;

    // const extractDomain = (url: string) => {
    //   try {
    //     const { hostname } = new URL(url);
    //     return hostname;
    //   } catch (e) {
    //     return null;
    //   }
    // };

    // const currentDomain = extractDomain(currentUrl);
    // const referrerDomain = extractDomain(referrerUrl);

    // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
    //   this.location.back();
    // } else {
    //   this.router.navigate(['/']);
    // }
    if (this.prevURL === this.router.url) {
      if (this.part === 'provider') {
        // this.router.navigate(['/statement']);
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { part: 'monthly' } });
      } else {
        this.router.navigate(['/']);
      }
    } else {
      // console.log(this.part);
      if (this.part === 'default') {
        this.router.navigate(['/']);
      } 
      else if(this.part === 'provider'){
        // this.router.navigate(['/statement']);
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { part: 'monthly' } });
      }else {
        // console.log(this.prevURL)
        this.router.navigateByUrl(this.prevURL);
      }
    }
  }
}

  handlePageEvent(e: PageEvent) {
    this.scrollToTop();
    this.pageEvent = e;
    // this.getData(e.pageIndex + 1);
    this.getDataDetail(e.pageIndex+ 1, this.currentDate);
  }

  state = State;

  currentState: State = State.Month;

  providerDate: string = '';
  providerId: string = '';
  providerName: string = '';

  searchText: string = '';
  items: any[] = [];
  filteredItems: string[] = [];

  changeMonth(value: number, currentMonth: number) {
    this.month = {
      code: value,
      value: dayjs().add(value, 'month').format('MMMM YYYY')
    }

    this.currentMonth = currentMonth;

    this.providerDate = '';
    this.providerId = '';
    this.providerName = '';
    this.getDataMonthly(1);
  }

  toogleDropdownProvider(){
    this.showDropdown = !this.showDropdown;
  }

  filterDropdownProvider(event: any) {
    if(event.target.value != '') {
      const inputValue = event.target.value.toLowerCase();
      const filteredList = this.providerListsAll.filter(provider =>
          provider.name.toLowerCase().includes(inputValue)
      );
      this.filterProviderLists = filteredList;
    } else {
      this.filterProviderLists = this.providerListsAll;
    }
  }

   focusTitle() {
  const titleElement = document.getElementById('title');
  if (titleElement) {
    titleElement.scrollIntoView({ behavior: 'smooth' });
  }
}



  changeDate(date: string) {
    this.statement.detail = {
      id: null,
      result: null
    };
    date = dayjs(date).format('YYYY-MM-DD');
    this.showDropdown = false;
    this.currentDate = date;
    this.getDataDetail(1, date);
  }

  changeDateProvider(date: string) {

    date = dayjs(date).format('YYYY-MM-DD');
    this.currentDate = date;
    this.getDataProvider(1, date);
  }

  changeState(newState: State) {
    this.currentState = newState;
  }

  getStatementResult(id: string, type: string) {
    this.showDropdown = false;
    this.request.getStatementResult(this.auth.sessionValue, id, {}, { page: this.pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          if (v.data.status !== 2) {
            setTimeout(() => {
              this.getStatementResult(id, type);
            }, 1000);
          } else {
            if (type === 'monthly') {
              this.statement.monthly.result = v;
            } else if (type === 'provider') {
              this.statement.provider.result = v;
              this.items = v.data.result.records
            } else {
              this.isLoadingDetail = false;
              if(this.statement.detail.result == null){
                this.statement.detail.result = v.data.result.records;
              } else {
                this.statement.detail.result.push(...v.data.result.records);
              }
              this.pagination.detail = [];
              this.maxPage = v.data.result.pagination.max_page;
              for (let i = 1; i <= v.data.result.pagination.max_page; i++) {
                this.pagination.detail.push(i);
              }
              this.length = v.data.result.pagination.total_records;
            }
            // this.scrollToTop();
          }
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.doc.body.scrollTo(0, 0)
    }, 200);
  }

  getDataMonthly(page: number) {
    this.statement = {
      monthly: {
        id: null,
        result: null
      },
      provider: {
        id: null,
        result: null
      },
      detail: {
        id: null,
        result: null
      }
    };

    this.page = {
      monthly: page,
      provider: 1,
      detail: 1
    };
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'monthly' } });
    this.subtitle = this.month.value;

    this.request.getStatementMonthly(this.auth.sessionValue, { date: { start: dayjs(this.month.value).format('YYYY-MM-01'), end: dayjs(this.month.value).format('YYYY-MM-31') } }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.changeState(State.Month);
          this.statement.monthly.id = v;
          this.getStatementResult(this.statement.monthly.id.data._id, 'monthly');
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  providerClick(date: string) {
    this.focusTitle();
    this.providerDate = date;
    this.providerId = '';
    this.providerName = '';
    this.getDataProvider(1);
  }


  getDataProvider(page: number, date?: string) {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'provider' } });
    this.statement.provider = {
      id: null,
      result: null
    };
    this.statement.detail = {
      id: null,
      result: null
    };

    this.page.provider = page;
    this.page.detail = 1;

    if (date) {
      date = dayjs(date).format('YYYY-MM-DD');
    } else {
      date = dayjs(this.providerDate).format('YYYY-MM-DD');
    }
    this.currentDate = date;

    // this.subtitle = ' - ' + dayjs(this.providerDate).format('DD/MMM/YYYY');
    this.subtitle = dayjs(date).format('DD MMM YYYY');
    this.request.getStatementProvider(this.auth.sessionValue, { date: { start: date, end: date } }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.changeState(State.Provider);
          this.statement.provider.id = v;
          this.getStatementResult(this.statement.provider.id.data._id, 'provider');
          console.log(this.currentDate, this.limitDate.end);

        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  detailClick(id: string, name: string) {
    this.focusTitle();
    this.providerId = id;
    this.providerName = name;
    this.statement.detail = {
      id: null,
      result: null
    };
    this.getDataDetail(1, this.currentDate);
  }

   getDataDetail(page: number, date?: string) {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'detail' } });
    this.page.detail = page;
    this.pageIndex = page;
    if (date) {
      date = dayjs(date).format('YYYY-MM-DD');
    } else {
      date = dayjs(this.providerDate).format('YYYY-MM-DD');
    }
    this.currentDate = date;
    // this.subtitle = ' - ' + dayjs(this.providerDate).format('DD/MMM/YYYY') + ' - ' + this.providerName;
    this.subtitle = dayjs(date).format('DD MMM YYYY');
    this.request.getStatementDetail(this.auth.sessionValue, { provider: this.providerId, date: { start: date, end: date } }, { page: this.pageIndex, limit: this.pageSize }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.changeState(State.Date);
          this.statement.detail.id = v;
          this.getStatementResult(this.statement.detail.id.data._id, 'detail');
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  clickBack() {
    if (this.statement.detail.id !== null) {
      this.getDataProvider(1, this.currentDate);
    } else if (this.statement.provider.id !== null) {
      this.getDataMonthly(1);
    }
  }

  IDRFormat(value: number) {
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);
    return Intl.NumberFormat('id-ID').format(roundedValue);
  }

  customIDRFormat(value: number){
    const valueWithoutCommas = value.toString().replace(/,/g, '');

    const numberValue = parseFloat(valueWithoutCommas);

    const roundedValue = Math.floor(numberValue);

    if(roundedValue < 0){
      return '- IDR ' + Intl.NumberFormat('id-ID').format(roundedValue*-1);
    } else {
      return 'IDR '+Intl.NumberFormat('id-ID').format(roundedValue);
    }
  }



  getAbs(value: number): number {
    return Math.abs(value);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD');
  }

  hourFormat(value: string) {
    return dayjs(value + 'Z').format('HH:mm:ss');
  }

  dateFormat2(value: string) {
    return dayjs(value + 'Z').format('YYYY-MM-DD HH:mm:ss');
  }

  getYesterdayDate(): string {
    return dayjs(this.currentDate).subtract(1, 'day').format('YYYY-MM-DD');
  }

  getTomorrowDate(): string {
    return dayjs(this.currentDate).add(1, 'day').format('YYYY-MM-DD');
  }

  formatDisplayDate(date: string): string {
    return dayjs(date).format('DD MMM');
  }

  isNotLimitStart(): boolean {
    return this.currentDate !== this.limitDate.start;
  }

  isNotLimitEnd(): boolean {
    return this.currentDate !== this.limitDate.end;
  }

  pageChange(page: number, type: string) {
    if (type === 'monthly') {
      this.getDataMonthly(this.page.monthly);
    } else if (type === 'provider') {
      this.getDataProvider(page);
    } else {
      this.getDataDetail(page);
    }
  }

  copyToClipboard(id: string): void {
    navigator.clipboard.writeText(id).then(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'ID Key berhasil disalin', type : 'mobile-default' },
        duration: 3000,
        panelClass: "notification-default"
      });
    }).catch(err => {
      console.error('Failed to copy ID to clipboard', err);
    });
  }


  ngOnInit(): void {
    this.providerListsAll = this.dataService.providerJSONData['records'] || [];
    this.filterProviderLists = this.providerListsAll;
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'monthly'){
        this.pageChange(1,'monthly')
      }
      if(params['part'] == 'provider'){
        this.pageChange(1,'provider')
      }
      if(params['part'] == 'detail'){
        this.pageChange(1,'detail')
      }

      if(params['part']){
        this.part = params['part']
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      const now = new Date();
      const today = dayjs();
      const threeMonthsAgo = dayjs().subtract(3, 'month');

      this.limitDate = {
        start: threeMonthsAgo.format('YYYY-MM-DD'),
        end: today.format('YYYY-MM-DD')
      };
      this.currentMonth = now.getMonth() + 1;
      this.getDataMonthly(1);
      this.subtitle = this.month.value;
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}

export enum State {
  Month = 'month',
  Date = 'date',
  Provider = 'provider'
}
export const MONTHS: { [key: number]: string } = {
  1: 'JANUARY',
  2: 'FEBRUARY',
  3: 'MARCH',
  4: 'APRIL',
  5: 'MAY',
  6: 'JUNE',
  7: 'JULY',
  8: 'AUGUST',
  9: 'SEPTEMBER',
  10: 'OCTOBER',
  11: 'NOVEMBER',
  12: 'DECEMBER'
};

