import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FooterComponent } from '@template/green/footer/footer.component';
import { AllGamesComponent } from '@template/green/parts/all-games/all-games.component';
import { DataService } from '@componentpath/services/data.service';
import { SidebarComponent } from '@template/green/parts/sidebar/sidebar.component';
import { Slideshow1Component } from '@template/green/parts/slideshow1/slideshow1.component';
import { HeaderSearchComponent } from '@template/green/header-search/header-search.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';

@Component({
  selector: 'app-provider',
  standalone: true,
  imports: [
    HeaderSearchComponent,
    FooterComponent,
    AllGamesComponent,
    SidebarComponent,
    Slideshow1Component,
    HeaderMobileComponent
  ],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.scss'
})
export class ProviderComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dataService: DataService,
   private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
      }
      else {
        this.currentScreenSize = 'desktop';
      }
    });
  }

  slideshowJSONData: any = this.dataService.slideshowJSONData;
  slideshowData: any = [];
  providerLists: Array<any> = [];

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.providerLists = this.dataService.providerJSONData['records'];
      for (const value of this.slideshowJSONData.records) {
        if (value.code === 'HOMEPAGE') {
          this.slideshowData = value.contents;
          break;

        }
      }
    }
  }
}