<div class="page-search">
    <app-sidebar class="d-none d-md-block"></app-sidebar>
    <div>
        @if(currentScreenSize === 'mobile'){
            <app-header-mobile [hideNews]="true"></app-header-mobile>
            <app-all-games [seeAll]="false" [provider]="true" />
        }@else{
            <app-header-search />
            <app-slideshow1 [data]="slideshowData" class="d-md-block d-none" />
            <app-all-games [seeAll]="false" [provider]="true" />
        }
    </div>
</div>
<app-footer />