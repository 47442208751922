<div class="header-container">
    <div class="header-container-inner">
        <div class="container-xxl header-container-inner-wrapper">
            <div class="header-middle">
                <div class="header-middle-center">
                    <a class="logo-header" [routerLink]="['/']">
                        <img [src]="websiteJSONData.header.logo" />
                    </a>
                </div>
                @if(isLoggedin){
                <div class="header-middle-right-loggedin d-flex">
                    <a [routerLink]="['/profile']">
                        <button class="d-flex user-profile-button gap-2">
                            <img src="../template/green/assets/images/user-profile.png" class="profile" />
                            <div class="text-profile">
                                <p class="text-nowrap text-uppercase">{{ username }}</p>
                                @if(balance == undefined){
                                <p class="text-nowrap fw-bold">Loading...</p>
                                } @else {
                                <p class="text-nowrap fw-bold">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                                }
                            </div>
                        </button>
                    </a>
                    <a [routerLink]="['/withdrawal']">
                        <button class="btn-withdraw d-flex align-items-center justify-content-center gap-1">
                            <img src="../template/green/assets/images/ic-withdraw.png"
                                class="custom-image-top-header hide-text-dp-wd" />
                            <div class="">WITHDRAW</div>
                        </button>
                    </a>
                    <a [routerLink]="['/deposit']">
                        <button class="btn-deposit d-flex align-items-center justify-content-center gap-1">
                            <img src="../template/green/assets/images/ic-deposit.png" class="custom-image-top-header hide-text-dp-wd" />
                            <div class="">DEPOSIT</div>
                        </button>
                    </a>
                    <button class="btn-logout" (click)="logout()">
                        <fa-icon [icon]="faPowerOff" class="close-button" size="xl"></fa-icon>
                    </button>
                    <!-- <button class="btn-logout d-none display-600-none" (click)="logout()">LOGOUT</button> -->
                </div>
                } @else{
                <div class="header-middle-right">
                    <a [routerLink]="['/login']">
                        <button class="btn-login">LOGIN</button>
                    </a>
                    <a [routerLink]="['/register']">
                        <button class="btn-register">REGISTER</button>
                    </a>
                </div>
                }
                <!-- mobile -->
            </div>
        </div>
        
    </div>
    <div class="menu-top d-block">
        <div class="container-xxl menu-top-left">
            <div class="hover-menu" (mouseleave)="clickHideMenu()">
                <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'slots'"
                    (mouseenter)="clickMenu('slots')">
                    <a> Slot </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'live-casino'"
                    (mouseenter)="clickMenu('live-casino')">
                    <a> casino </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'fish-hunter'"
                    (mouseenter)="clickMenu('fish-hunter')">
                    <a> arcade </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'sportbook'"
                    (mouseenter)="clickMenu('sportbook')">
                    <a> sportbook </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <div class="menu-top-left-item"
                    [class.menu-top-left-item-active]="activeMenu === 'crash'" (mouseenter)="clickMenu('crash')">
                    <!-- <a [routerLink]="['/search']" [queryParams]="{'tags':('CRASH' | enCodeUri)}"> crash </a> -->
                    <a> crash </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <!-- <div class="menu-top-left-item d-none d-lg-block hide-tablet-600 hide-tablet-800"
                    [class.menu-top-left-item-active]="activeMenu === 'togel'">
                    <a [routerLink]="['/search']" [queryParams]="{'categories':('LT' | enCodeUri)}"> togel </a>
                </div> -->
                <div class="menu-top-left-item"
                    [class.menu-top-left-item-active]="activeMenu === 'esport'" (mouseenter)="clickMenu('esport')">
                    <!-- <a [routerLink]="['/search']" [queryParams]="{'categories':('ES' | enCodeUri)}"> esport </a> -->
                    <a> esport </a>
                    <img src="../template/green/assets/images/ic-chevron-down.png" />
                </div>
                <div class="megamenu">
                    <div class="container-xxl">
                        <div class="row">
                            <div class="col-12 swiper-max-h">
                                <div class="row">
                                    <div>
                                        <swiper-container #swiperContainer class="swiper-max-h align-items-center">
                                            @for (item of selectedProvider; track item.code;) {
                                            <swiper-slide class="swiper-item">
                                                <div class="container-provider-menu">
                                                    @for(provider of item; track provider.code;){
                                                    <a [routerLink]="['/search']"
                                                        [queryParams]="{'categories':(currentProviderCode | enCodeUri),'providers':(provider['code'] | enCodeUri)}">
                                                        <img ngSrc="{{provider.image_url}}" [title]="provider.name"
                                                            [alt]="provider.name" width="150" height="150" loading="eager" />
                                                    </a>
                                                    }
                                                </div>
                                            </swiper-slide>
                                            }
                                        </swiper-container>
                                        <div className="swiper-paginations"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-top-left-item hide-tablet-600" [class.menu-top-left-item-active]="activeMenu === 'promosi'"
                (click)="navigateToPromotion()">
                <a> promosi </a>
            </div>
            @if(showReferral){
                <div class="menu-top-left-item hide-tablet-600"
                    [class.menu-top-left-item-active]="activeMenu === 'referral'" (click)="navigateToReferral()">
                    <a> referral </a>
                </div>
            }
            @if(websiteJSONData['contact']['livechat']){
            <div class="menu-top-left-item hide-menu-livechat hide-tablet-600"
                [class.menu-top-left-item-active]="activeMenu === 'livechat'">
                <a [href]="livechatURL"> livechat </a>
            </div>
            }
        </div>
    </div>

    @if (pageTitle) {
    <!-- <div
    class="header-container-mobile position-absolute top-0 d-flex d-md-none pt-3"
    *ngIf="!isLoggedin"
  >
    <button class="btn outline-0 border-0 ml-1" (click)="navigateToHome()">
      <img src="../template/green/assets/images/icon-back.png" />
    </button>
    <div class="h4 text-uppercase fw-bold text-white text-center header-title m-0">
      {{ pageTitle }}
    </div>
  </div> -->
    } @else {
    @if(showNewsticker){
    <div class="menu-bottom">
        <div class="header-bottom-center">
            <div class="latest-news container-xxl">
                <div class="latest-news-left">
                    <img src="../template/green/assets/images/ic-bullhorn.png" width="12" height="10" />
                </div>
                <div class="latest-news-right">
                    <div class="latest-news-right-inner">
                        <app-newsticker [data]="newstickerData"></app-newsticker>
                    </div>
                </div>
                <a class="container-event-click" [routerLink]="['/event']">
                    <div class="event-button-container">
                        <img class="trophy" src="../template/green/assets/images/header/trophy-uno.gif" />
                        <img class="event" src="../template/green/assets/images/header/EVENT.png" />
                    </div>
                </a>
            </div>
        </div>
    </div>
    }
    }
    @if(openMenu){
    <div class="hamburger-mobile">
        @if(isLoggedin){
        <div class="position-relative top-menu">
            <div class="user-info">
                <img src="../../../template/green/assets/images/status-gold.png" class="img-status" />
                <div class="detail-info">
                    <div>
                        <p class="username">{{ username }}</p>
                        @if(balance == undefined){
                        <p class="balance">Loading...</p>
                        } @else {
                        <p class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                        }
                    </div>
                    <img src="../../../template/green/assets/images/label-gold-mobile.png" width="40px" height="32px" />
                </div>
                <div class="side-btn">
                    <button>
                        <fa-icon [icon]="faEdit"></fa-icon>
                        <span class="m-0 p-0">EDIT PROFILE</span>
                    </button>
                </div>
            </div>
            <div class="submenu">
                <div>
                    <img src="../../../template/green/assets/images/ic-deposit.svg" alt="Deposit" />
                    <a [routerLink]="['/deposit']" class="hide-text-dp-wd">Deposit </a>
                </div>
                <div>
                    <img src="../../../template/green/assets/images/ic-withdraw.svg" alt="Withdraw" />
                    <a [routerLink]="['/withdrawal']" class="hide-text-dp-wd">Withdraw</a>
                </div>F
                <div>
                    <img src="../../../template/green/assets/images/ic-statement.svg" alt="Statement" />
                    <a [routerLink]="['/statement']">Statement</a>
                </div>
                <div>
                    <img src="../../../template/green/assets/images/ic-transaction.svg" alt="Transaction" />
                    <a [routerLink]="['/transaction']">Transaction</a>
                </div>
                <div>
                    <img src="../../../template/green/assets/images/ic-saldo.svg" alt="Saldo" />
                    <a [routerLink]="['/saldo']">Saldo</a>
                </div>
            </div>
        </div>
        } @else {
        <div class="auth-btn-container d-flex m-0 p-0">
            <a [routerLink]="['/login']">
                <button class="btn-login">LOGIN</button>
            </a>
            <a [routerLink]="['/register']">
                <button class="btn-register">REGISTER</button>
            </a>
        </div>
        }
        <div class="position-relative d-flex flex-column language">
            <button (click)="openLang = !openLang">
                <div class="d-flex">
                    <fa-icon [icon]="faGlobe"></fa-icon>
                    <p class="m-0 p-0">{{ lang }}</p>
                </div>
                <fa-icon [icon]="faCaretDown"></fa-icon>
            </button>
            @if(openLang){
            <div class="lang-dropdown">
                <span (click)="chooseLang('Indonesia')">Indonesia</span>
                <span (click)="chooseLang('English')">English</span>
            </div>
            }
        </div>
        @if(websiteJSONData.configuration.apk_url){
        <div class="apk">
            <p>Akses Lebih Cepat & Mudah Download Aplikasi Kami Sekarang!</p>
            <a [href]="websiteJSONData.configuration.apk_url" class="btn-download">
                Download Now</a>
        </div>
        }
        <div class="list-promotion">
            <app-slideshow-promotion [data]="sideBannerData" />
        </div>
        <div class="list-menu-middle">
            <div class="menu-card">
                <p class="title">Promosi</p>
                <p class="desc">Dapatkan promosi terbaik untuk kemenangan maksimal</p>
                <img src="../../../template/green/assets/images/mobile-promosi.png" alt="Promosi" />
            </div>
            @if(showReferral){
                <div class="menu-card">
                    <p class="title">Referral</p>
                    <p class="desc">Ajak temanmu dan dapatkan keuntungannya</p>
                    <img src="../../../template/green/assets/images/mobile-referral.png" alt="Referral" />
                </div>
            }
            <div class="menu-card">
                <p class="title">Event</p>
                <p class="desc">Lihat event terbaru dengan hadiah terbesar</p>
                <img src="../../../template/green/assets/images/mobile-event.png" alt="Event" />
            </div>
            <div class="menu-card">
                <p class="title">Contact</p>
                <p class="desc">Hubungi kami untuk segala pertanyaan kamu</p>
                <img src="../../../template/green/assets/images/mobile-contact.png" alt="Contact" />
            </div>
        </div>
        <div class="list-menu-bottom">
            <a class="detail" [routerLink]="['/']">
                <p>Beranda</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
            <button class="detail" (click)="redirect('SL')">
                <p>Slot</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('LC')">
                <p>Live Casino</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('FH')">
                <p>Arcade</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('FH')">
                <p>Lottery</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <button class="detail" (click)="redirect('ES')">
                <p>Esports</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
            <a class="detail" [routerLink]="['/']">
                <p>Providers</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </a>
            <button class="detail logout" [class.d-none]="!isLoggedin" (click)="logout()">
                <p>Sign Out</p>
                <fa-icon [icon]="faAngleRight"></fa-icon>
            </button>
        </div>
    </div>
    }
</div>