<div class="jackpot px-0 mx-0" [ngClass]="hideLiveScore? 'hide-livescore':''">
  <div class="container-xxl d-block d-md-none px-0 mx-0 jackpot-inner">
    <span class="value mobile" #a>
      <div class="blink-text">IDR {{jackpotGlobalVal | formatNumberId}}</div>
    </span>
    <img ngSrc="../../../assets/orianna/images/jackpot.gif" width="951" height="136" priority />
  </div>
  <div class="container-xxl d-none d-md-block px-0 mx-0 jackpot-inner">
    <span class="value" style="justify-content: flex-end !important;" #a>
      <div class="blink-text" style="font-size: 3vw; margin-right: 60px;">IDR {{jackpotGlobalVal | formatNumberId}}</div>
    </span>
    <img ngSrc="../../../assets/orianna/images/jackpot_desktop.gif" width="951" height="136" priority />
  </div>
</div>
