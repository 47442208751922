<div class="page-deposit">
    @if(currentScreenSize === 'xSmall'){
        <app-deposit-mobile></app-deposit-mobile>
    }@else {
        <app-header-simple />
        <div class="container-xxl m-auto">
            <div class="title-page h2 my-5">
                DEPOSIT
            </div>
    
            @if(loader) {
            <div class="spinner-container">
                <div class="spinner-border" style="margin: 0 auto;color: #fff;" role="status"></div>
            </div>
            }
            @if(!showFormPaymentAuto && !showFormPaymentManual &&!loader){
                @if(!nextStepOption){
                    <div class="row align-items-center main-deposit d-flex py-5 mb-5">
                        <div class="title-page-small h5">
                            Pilih Opsi Pembayaran
                        </div>
                        <div class="option-payment">
                            <div class="auto-button-big mt-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' " (click)="selectOptionPayment('auto')">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/auto.png">
                                    <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">Auto Deposit</span>
                                    <span class="text-sub">Deposit dengan payment gateway</span>
                                </div>
                                <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                            </div>
                            <div class="manual-button-big mt-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''" (click)="selectOptionPayment('manual')">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/manual.png">
                                    <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">Manual Deposit</span>
                                    <span class="text-sub">Deposit transfer bank dan lainnya melalui admin</span>
                                </div>
                                <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                            </div>
            
                            <button  type="button" class="btn btn-select-deposit mt-4" (click)="nextButtonOption()">SELANJUTNYA</button>
                        </div>
                    
                    </div>
                } @else {
                    <div class="row align-items-center main-deposit d-flex py-5 mb-5">
                        <div class="title-page-small h5">
                            Pilih Metode Pembayaran
                        </div>
                        <div class="option-payment">
                            <div class="auto-button-big mt-4" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':'' "
                                (click)="selectMethodPayment('vacc')">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/vacc.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">Virtual Account</span>
                                    <span class="text-sub">Transfer dengan cepat ke akun bank virtual pilihan anda</span>
                                </div>
                                <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                            </div>
                            <div class="manual-button-big mt-4" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                                (click)="selectMethodPayment('qris')">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/qris.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">QRIS</span>
                                    <span class="text-sub">Pembayaran cepat dan mudah dengan scan barcode QRIS</span>
                                </div>
                                <fa-icon [icon]="faCircleQuestion" [size]="'xl'"></fa-icon>
                            </div>
                    
                            <button type="button" class="btn btn-select-deposit mt-4" (click)="nextButtonMethod()">SELANJUTNYA</button>
                        </div>
                    
                    </div>
                }
            } @else{
                @if(!hideSwitchMenu && depositConfig['status']){
                    <div class="container-menu-option">
                        <div class="small-option-payment">
                            <div class="auto-button-small mb-4" [ngClass]="currentOptionPayment === 'auto' ? 'active-button':'' "
                                (click)="selectOptionPayment('auto'); showFormPaymentAuto = true">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/auto.png">
                                    <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">Auto Deposit</span>
                                    <span class="text-sub">Deposit dengan payment gateway</span>
                                </div>
                            </div>
                            <div class="manual-button-small mb-4" [ngClass]="currentOptionPayment === 'manual' ? 'active-button':''"
                                (click)="selectOptionPayment('manual'); showFormPaymentManual = true">
                                <div class="img-button">
                                    <img src="../../../template/green/assets/images/manual.png">
                                    <img class="img-check" src="../../../template/green/assets/images/check_gold.png">
                                </div>
                                <div class="text-container">
                                    <span class="text-main">Manual Deposit</span>
                                    <span class="text-sub">Deposit transfer bank melalui admin</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
    
                @if(showFormPaymentAuto && currentOptionPayment ==='auto'){
                    <div class="row main-deposit container-auto-deposit d-flex mb-5">
                        <div class="side-bar-deposit" [ngStyle]="{'height':(!showConfirmPayment && currentMethodPayment === 'qris')? '505px': (showConfirmPayment && currentMethodPayment === 'vacc')? '525px': (showConfirmPayment && currentMethodPayment === 'qris')?'520px':'600px'}">
                            <div class="status-balance-user">
                                <div class="badge-user">
                                    <img src="../../../template/green/assets/images/status-gold.png">
                                </div>
                                <div class="data-user">
                                    <span class="group-user">
                                        <img src="../../../template/green/assets/images/label-gold.png">
                                    </span>
                                    <span class="username">TEST</span>
                                    <span class="balance">IDR {{11231231 | currency:' ' : 'symbol' : '1.0-0'}}</span>
                                </div>
                            </div>
                            @if(depositConfig['virtual_account']){
                                <div class="pay-vacc" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':''" (click)="selectMethodPayment('vacc')">
                                    <span class="img-icon">
                                        <img src="../../../template/green/assets/images/vacc.png">
                                    </span>
                                    <span class="text-menu">Pay via Virtual Account</span>
                                    <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                                </div>
                            }
                            @if(depositConfig['qris']){
                                <div class="pay-qris" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''" (click)="selectMethodPayment('qris')">
                                    <span class="img-icon">
                                        <img src="../../../template/green/assets/images/qris.png">
                                    </span>
                                    <span class="text-menu">Pay via QRIS</span>
                                    <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                                </div>
                            }
                        </div>
                        @if(!showConfirmPayment){
                            <div class="container-payment">
                                @if(currentMethodPayment === 'vacc'){
                                    <div class="choose-bank">
                                        <label class="label-input">Pilih Bank Tujuan</label>
                                        <div ngbDropdown class="d-inline-block w-100">
                                            <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank" ngbDropdownToggle>
                                                BANK
                                            </button>
                                            <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                                                <button ngbDropdownItem></button>
                                                <button ngbDropdownItem></button>
                                                <button ngbDropdownItem></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div class="input-amount">
                                    <label class="label-input">Masukan Nominal Deposit</label>
                                    <div class="d-inline-block w-100">
                                        <div class="container-input">
                                            <label for="inputAmount" class="label-input-small">Masukan Nominal</label>
                                            <input id="inputAmount" type="text" spellcheck="false" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" placeholder=" " [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')" (ngModelChange)="updateValueNominal($event)" name="inputAmount"
                                                aria-label="Masukan Nominal" />
                                        </div>
                                        <div class="chip-amount-container">
                                            <swiper-container appSwiper [config]="config" class="swiper-container">
                                                @for (item of arrayNominal; track item;) {
                                                <swiper-slide class="swiper-item">
                                                    <div class="chip-amount" [ngClass]="currentNominal === item ? 'active-chip':''" (click)="setNominalChip(item)">Rp {{item | currency:' ' : 'symbol' : '1.0-0'}}</div>
                                                </swiper-slide>
                                                }
                                            </swiper-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="choose-promo">
                                    <label class="label-input">Pilih Voucher Promo</label>
                                    <div class="d-inline-block w-100">
                                        <div class="container-promo-bar active-promo">
                                            <span class="img-icon">
                                                <img src="../../../template/green/assets/images/voucher_applied.png">
                                            </span>
                                            <span class="text-status">Voucher Applied</span>
                                            <span class="text-name-promo">BONUS NEW MEMBER CASINO TEST</span>
                                            <fa-icon [icon]="faTrashCan"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="transaction-detail">
                                    <div class="container-rule-payment">
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            @if(depositConfig['handled_by'] === 1){
                                                Transaction Fee {{depositConfig['percentage']}}% + {{depositConfig['fix_amount']}}
                                            } @else {
                                                Transaction Fee FREE
                                            }
                                        </span>
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Minimum Jumlah Deposit 10.000
                                        </span>
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Maksimum Jumlah Deposit 10.000.000
                                        </span>
                                    </div>
                                    <div class="container-payment-detail">
                                        <div class="jumlah-deposit">
                                            <span>Jumlah Deposit</span>
                                            <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                        </div>
                                        <div class="biaya-transaksi">
                                            <span>Biaya Transaksi</span> 
                                            @if(depositConfig['handled_by'] === 1){
                                                <span>IDR {{inputAmount ? (inputAmount * (depositConfig['percentage'] / 100))+depositConfig['fix_amount'] : 0}}</span>
                                            } @else {
                                                FREE
                                            }
                                        </div>
                                        <div class="total-pembayaran">
                                            <span>Total Pembayaran</span>
                                            @if(depositConfig['handled_by'] === 1){
                                                <span>IDR {{inputAmount ? (inputAmount+((inputAmount * (depositConfig['percentage'] / 100))+depositConfig['fix_amount']) | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                            }@else{
                                                <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
    
                                <button type="button" class="btn btn-confirm-deposit mt-2" (click)="confirmDeposit()">DEPOSIT</button>
                            </div>
                        } @else {
                            <div class="container-payment container-detail-transfer">
                                @if(currentMethodPayment  === 'vacc'){
                                    <div class="area-detail-payment">
                                        <div class="detail-bank">
                                            <span class="sub-title">Lakukan Pembayaran via Bank</span>
                                            <div class="bank-data">
                                                <img src="../../../template/green/assets/images/qris.png">
                                                <span class="bank-name">Standard Chartered</span>
                                            </div>
                                        </div>
                                        <div class="vacc-detail">
                                            <span class="sub-title">Nomor Virtual</span>
                                            <span class="vacc-number">6792929283474582892929</span>
                                        </div>
                                        <div class="transfer-detail">
                                            <span class="sub-title">Jumlah Transfer</span>
                                            <div class="nominal-transfer">IDR <span class="value-transfer">55.0000</span></div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-check-deposit mt-2">Cek Status Pembayaran</button>
                                    <button type="button" class="btn btn-cancel-deposit">Batalkan Pembayaran Virtual Account</button>
                                }@else {
                                    <div class="area-qris-payment">
                                        <div class="qris-image">
                                            <img src="../../../template/green/assets/images/qris.png">
                                        </div>
                                        <div class="area-detail-container">
                                            <div class="area-detail-payment">
                                                <div class="time-expired">
                                                    Berlaku Hingga <span class="count-time">(04:27)</span>
                                                </div>
                                                <div class="qris-detail">
                                                    <span class="sub-title">Nama Rekening</span>
                                                    <span class="user-name">TESTER</span>
                                                </div>
                                                <div class="transfer-detail">
                                                    <span class="sub-title">Jumlah Harus Dibayarkan</span>
                                                    <div class="nominal-transfer">IDR55.0000</div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-download-qr mt-3">Download QR</button>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-check-deposit mt-3">Cek Status Pembayaran</button>
                                    <button type="button" class="btn btn-cancel-deposit">Batalkan Pembayaran Virtual Account</button>
                                }
                            </div>
                        }
                    </div>
                }
    
                @if(showFormPaymentManual && currentOptionPayment === "manual" && !loader){
                    <app-deposit-manual></app-deposit-manual>
                }
    
            }
        </div>
        <app-footer [data]="footerData" />
    }
</div>