import { CurrencyPipe, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
@Component({
  selector: 'app-jackpot-tablet',
  standalone: true,
  imports: [FormatNumberIdPipe, NgOptimizedImage],
  templateUrl: './jackpot-tablet.component.html',
  styleUrl: './jackpot-tablet.component.scss'
})
export class JackpotTabletComponent {
  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
    if (document.hidden) {
      this.cancelJackpotGlobal();
    }
    else {
      this.secondRun = false;
      this.hiddenActive = false;

      if (!this.firstRun) {
        this.getJackpotGlobal();
      }
    }
  }

  @ViewChild('a') a: any;

  windowAnimation: number = 0;
  jackpotTimeout: undefined | NodeJS.Timeout;
  frameTimeout: undefined | NodeJS.Timeout;
  globalJackpotSecond: undefined | NodeJS.Timeout;
  // jackpotGlobalVal: string = '';
  firstJackpotGlobalVal: string = '';
  themeName: string = '';


  secondRun: boolean = false;
  firstRun: boolean = false;
  hiddenActive: boolean = false;
  cAnimated: boolean = false;
  dAnimated: boolean = false;

  jackpotGlobalVal: number = 1000000;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private requestService: RequestService,
  ) {
  }

  getJackpotGlobal() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.hiddenActive) {
        return
      }
      this.firstRun = true;
      this.requestService.getJackpotGlobal().subscribe({
        next: (v) => {
          const value = v as Record<string, any>;
          if (value['code'] === 200) {
            const resultData = value['data'] as Record<string, any>;
            let customValue = parseInt(resultData['jackpot']);
            this.jackpotGlobalVal = Math.round(customValue);

            this.a.nativeElement.innerHTML = "<div class='blink-text'>"+ 'IDR ' + CurrencyPipe.prototype.transform(this.jackpotGlobalVal, ' ', 'symbol', '1.0-0', 'id-ID') + "</div>";

            if (this.hiddenActive) {
              return
            }

            if (!this.secondRun) {
              this.firstJackpotGlobalVal = Math.round(customValue).toString();
              this.globalJackpotSecond = setTimeout(() => {
                this.secondRun = true;
                this.getJackpotGlobal();
              }, 5000);

            } else {
              this.valueAnimationJackpot();

              if (this.jackpotTimeout === undefined) {
                this.updateJackpotGlobal();
              }
            }

          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  updateJackpotGlobal() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.hiddenActive) {
        return
      }
      this.jackpotTimeout = setTimeout(() => {
        this.requestService.getJackpotGlobal().subscribe({
          next: (v) => {
            const value = v as Record<string, any>;
            if (value['code'] === 200) {
              const resultData = value['data'] as Record<string, any>;
              let customValue = parseInt(resultData['jackpot']);
              this.jackpotGlobalVal = Math.round(customValue);
              this.valueAnimationJackpot();
              this.updateJackpotGlobal();
            }
            else {
              // clearTimeout(this.jackpotTimeout);
              // if (this.frameTimeout !== undefined) {
              //   clearInterval(this.frameTimeout);
              // }
              // if (this.globalJackpotSecond !== undefined) {
              //   clearInterval(this.globalJackpotSecond);
              // }
              this.cancelJackpotGlobal();
            }
          },
          error: (e) => {
            console.log(e);
            // clearTimeout(this.jackpotTimeout);
            // if (this.frameTimeout !== undefined) {
            //   clearInterval(this.frameTimeout);
            // }
            // if (this.globalJackpotSecond !== undefined) {
            //   clearInterval(this.globalJackpotSecond);
            // }
            this.cancelJackpotGlobal();
          },
          complete: () => { }
        })
      }, 15000);
    }
  }


  valueAnimationJackpot() {
    if (isPlatformBrowser(this.platformId)) {
      this.animateValue(this.a, parseInt(this.firstJackpotGlobalVal), this.jackpotGlobalVal, 10000);
    }
  }

  animateValue(obj: any, start: number, end: number, duration: number) {
    if (isPlatformBrowser(this.platformId)) {
      let startTimestamp: any = null;
      const step = (timestamp: number) => {

        if (!startTimestamp) startTimestamp = timestamp;

        const progress = Math.min((timestamp - startTimestamp) / duration, 1);

        if (progress === 0) {
          if (end > start) {
            // this.soundJackpot('play');
          }
        }

        if (progress < 1) {
          if (end > start) {
            obj.nativeElement.innerHTML = "<div style='color:var(--jp-color-text-2);'>" + 'IDR ' +  CurrencyPipe.prototype.transform(Math.floor(progress * (end - start) + start), ' ', 'symbol', '1.0-0', 'id-ID') + "</div>";
          }
          if (start > end) {
            obj.nativeElement.innerHTML = "<div style='color:var(--jp-color-text-3);'>" + 'IDR ' +  CurrencyPipe.prototype.transform(Math.floor(progress * (end - start) + start), ' ', 'symbol', '1.0-0', 'id-ID') + "</div>";
          }
          this.frameTimeout = setTimeout(() => {
            // if(!this.windowAnimation){
            this.windowAnimation = window.requestAnimationFrame(step);
            // }
          }, 180);
        }
        if (progress === 1) {
          obj.nativeElement.innerHTML = "<div class='blink-text'>" + 'IDR ' +  CurrencyPipe.prototype.transform(Math.floor(progress * (end - start) + start), ' ', 'symbol', '1.0-0', 'id-ID') + "</div>";
        }
      };
      this.windowAnimation = window.requestAnimationFrame(step);
      this.firstJackpotGlobalVal = Math.round(end).toString();
    }
  }

  cancelJackpotGlobal() {
    if (isPlatformBrowser(this.platformId)) {
      this.hiddenActive = true;
      this.firstRun = false;
      // this.soundJackpot('stop');
      if (this.frameTimeout !== undefined) {
        clearInterval(this.frameTimeout);
        window.cancelAnimationFrame(this.windowAnimation);
        this.frameTimeout = undefined;
      }

      if (this.globalJackpotSecond !== undefined) {
        clearInterval(this.globalJackpotSecond);
        this.globalJackpotSecond = undefined;
      }

      if (this.jackpotTimeout !== undefined) {
        clearInterval(this.jackpotTimeout);
        this.jackpotTimeout = undefined;
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.firstRun) {
        this.getJackpotGlobal();
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      // this.soundJackpot('stop');
      if (this.jackpotTimeout !== undefined) {
        clearInterval(this.jackpotTimeout);
      }
      if (this.frameTimeout !== undefined) {
        clearInterval(this.frameTimeout);
      }
      if (this.globalJackpotSecond !== undefined) {
        clearInterval(this.globalJackpotSecond);
      }
    }
  }
}
